import React from "react";
import * as style from "./style.module.scss";
import { StaticImage } from "gatsby-plugin-image";

const WhatsappIcon = (props) => {
    const imagePath = "../../../images/";

  return (
      <div className={style.boxImage}>
        <StaticImage src={`${imagePath}wa-logo.png`} alt="wa icon"/>
      </div>
  );
};

export default WhatsappIcon;
