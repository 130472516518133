import * as React from "react";
import styled from "styled-components";
import { StaticImage } from "gatsby-plugin-image";
import { black, grey1, lightGrey, TABLET_MAX_QUERY, TABLET_MIN_QUERY } from "../theme/variables";

const FooterContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	background-color: ${black};
	padding: 0.5rem 2rem calc(10rem + 100px);
	font-size: 0.8rem;
	${TABLET_MAX_QUERY} {
		flex-direction: column;
	}
	${TABLET_MIN_QUERY} {
		padding: 0.5rem 2rem calc(0.5rem + 100px);
	}
`;

const FooterLink = styled.a`
	color: ${grey1};
	text-decoration: none;
	margin: 0 5px;
	${TABLET_MAX_QUERY} {
		margin: 10px 0;
	}
	:hover,
	:focus {
		color: ${lightGrey};
	}
`;

interface FooterLinkModel {
	name: string;
	link: string;
}

/*const footerLinks: FooterLinkModel[] = [
  {
    name: "INFORMAZIONI LEGALI",
    link: "https://assets.pirellicare.com/docs/legal/PirelliCare_TCs_IT_it.pdf",
  },
  {
    name: "PRIVACY POLICY",
    link: "https://assets.pirellicare.com/docs/legal/PirelliCare_PP_IT_it.pdf",
  },
  {
    name: "COOKIE POLICY",
    link: "https://assets.pirellicare.com/docs/legal/PirelliCare_CP_IT_it.html",
  },
  {
    name: "LAVORA CON NOI",
    link: "https://corporate.pirelli.com/corporate/it-it/carriere/carriere",
  },
];*/

const Footer: React.FC = (props) => {
	const footerLinks: FooterLinkModel[] = props.footerDatas;
	let currentYear = new Date().getFullYear();

	const footerLinkNodes = footerLinks.map((value, index) => (
		<FooterLink target="_blank" href={value.link} key={index}>
			{value.name}
		</FooterLink>
	));

	return (
		<FooterContainer>
			{/* <div style={{ width: "167px" }}>
				<StaticImage src="../images/logo_150_yellow.png" alt="Pirelli" title="Pirelli" objectFit="contain" />
			</div> */}
			<svg xmlns="http://www.w3.org/2000/svg" width="167" height="52" viewBox="0 0 167 52" fill="none" style={{ margin: "10px 0" }}>
				<path fillRule="evenodd" clipRule="evenodd" d="M0 52H167V0H0V52Z" fill="#FFDD00"></path>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M61.6731 28.5053L57.4413 28.516V32.256L61.6823 32.2586C62.7022 32.2586 63.5304 31.4186 63.5304 30.3826C63.5304 29.3453 62.7022 28.5053 61.6731 28.5053ZM48.979 25.168H66.9064C69.8217 25.168 72.1857 27.5693 72.1857 30.5306C72.1857 32.7893 70.8114 34.7213 68.8674 35.512L72.5637 40.9266H62.3596L59.7318 35.8933H57.4269V40.9266H48.979V25.168Z"
					fill="#E73024"
				></path>
				<path fillRule="evenodd" clipRule="evenodd" d="M110.301 37.4593H118.992V40.9247H101.529V25.166H110.301V37.4593Z" fill="#E73024"></path>
				<path fillRule="evenodd" clipRule="evenodd" d="M85.935 37.4593H96.6563V40.9247H77.0015V25.166H95.6823V28.6313H85.935V31.3127H95.6823V34.778H85.935V37.4593Z" fill="#E73024"></path>
				<path fillRule="evenodd" clipRule="evenodd" d="M130.282 37.4593H138.973V40.9247H121.511V25.166H130.282V37.4593Z" fill="#E73024"></path>
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M130.668 14.5662H29.1613V18.2369H130.668C131.666 18.2369 132.476 17.4155 132.476 16.4022C132.476 15.3875 131.666 14.5662 130.668 14.5662ZM14.3789 10.9355H29.1613H146.994C149.988 10.9355 152.415 13.4009 152.415 16.4422C152.415 19.4849 149.988 21.9502 146.994 21.9502H29.1613V40.9262H14.3789V21.9502V10.9355Z"
					fill="#E73024"
				></path>
				<mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="167" height="52">
					<path fillRule="evenodd" clipRule="evenodd" d="M0 52H166.794V0.0839844H0V52Z" fill="white"></path>
				</mask>
				<g mask="url(#mask0)">
					<path fillRule="evenodd" clipRule="evenodd" d="M34.8467 40.9247H43.2932V25.166H34.8467V40.9247Z" fill="#E73024"></path>
					<path fillRule="evenodd" clipRule="evenodd" d="M141.408 40.9247H149.856V25.166H141.408V40.9247Z" fill="#E73024"></path>
				</g>
			</svg>
			{footerLinkNodes}
			<span style={{ color: "white", margin: "10px", textAlign: "center" }}>Copyright © {currentYear} Pirelli & C. S.p.A. - Pirelli Tyre S.p.A.</span>
		</FooterContainer>
	);
};

export default Footer;
