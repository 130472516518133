import * as React from "react";

const IconIta: React.FC = () => (
  <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill="#008C45" d="M0 0h6v20H0z" />
    <path fill="#F4F5F0" d="M6 0h8v20H6z" />
    <path fill="#CD212A" d="M14 0h6v20h-6z" />
  </svg>
);

export default IconIta;
