import * as React from "react";
import * as style from "./style.module.scss";
import IconEng from "../Icons/IconEng";
import IconIta from "../Icons/IconIta";

const ButtonChangeLanguage = ({ countryCode, reference, toggle, changeToggle }) => {

  return (
    <div className={style.buttonChangeLanguage}>
      <div
        className={`${style.currentLanguage} ${toggle ? style.open : ""} btnDropdown`}
        onClick={() => changeToggle(!toggle)}
        role="button"
        ref={reference}
        tabIndex={0}
      >
        <p>{countryCode === "it" ? "ITA" : "EN"}</p>
      </div>

      <div className={`${style.languagesList} ${toggle ? style.visible : ""}`}>
        {
          <a
            href={`${process.env.GATSBY_SITE_URL}${
              countryCode !== "it" ? "/it" : "/en"
            }`}
          >
            {countryCode === "it" ? <IconEng /> : <IconIta />}
            <p>{countryCode === "it" ? "EN" : "ITA"}</p>
          </a>
        }
      </div>
    </div>
  );
};

export default ButtonChangeLanguage;
