import * as React from "react";
import * as style from "./style.module.scss";
import ButtonChangeLanguage from "../ButtonChangeLanguage";
import Logo from "../Logo";
import { StaticImage } from "gatsby-plugin-image";
import ButtonSupport from "../ButtonSupport";
import ButtonRicaricaElettricaEv from "../ButtonRicaricaElettricaEv";

const Navbar = ({ countryCode, supports, handleScroll, hideComponent, ricaricaElettrica }) => {
	const [toggle, setToggle] = React.useState(false);
	const [toggleSupport, setToggleSupport] = React.useState(false);

	const btnRef = React.useRef();
	const btnRefSupport = React.useRef();

	React.useEffect(() => {
		const closeDropdown = (e) => {
			if (e.target.closest(".btnDropdown") !== btnRef.current) {
				setToggle(false);
			}
		};

		const closeDropdownSupport = (e) => {
			if (e.target.closest(".btnDropdownSupport") !== btnRefSupport.current) {
				setToggleSupport(false);
			}
		};

		document.body.addEventListener("click", closeDropdown);
		document.body.addEventListener("click", closeDropdownSupport);

		return () => document.body.removeElementListener("click", closeDropdown);
		document.body.removeElementListener("click", closeDropdownSupport);
	}, []);

	return (
		<div className={style.navbar}>
			<div className={style.navbar_content_container}>
				<div className={style.wrapper_pirellicare_logo}>
					<a href={`${process.env.GATSBY_SITE_URL}/${countryCode}`}>
						<Logo />
					</a>
				</div>

				<div className={style.wrapper_logo_btnChangeLang}>
					<div className={style.wrapperLogoReturnBack}>
						{ricaricaElettrica && (
							<div className={style.goBackIcon}>
								<a href={`${process.env.GATSBY_SITE_URL}/${countryCode}`} />
							</div>
						)}

						<div className={style.logo}>
							<svg xmlns="http://www.w3.org/2000/svg" width="167" height="52" viewBox="0 0 167 52" fill="none" style={{ margin: "10px 0" }}>
								<path fillRule="evenodd" clipRule="evenodd" d="M0 52H167V0H0V52Z" fill="#FFDD00"></path>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M61.6731 28.5053L57.4413 28.516V32.256L61.6823 32.2586C62.7022 32.2586 63.5304 31.4186 63.5304 30.3826C63.5304 29.3453 62.7022 28.5053 61.6731 28.5053ZM48.979 25.168H66.9064C69.8217 25.168 72.1857 27.5693 72.1857 30.5306C72.1857 32.7893 70.8114 34.7213 68.8674 35.512L72.5637 40.9266H62.3596L59.7318 35.8933H57.4269V40.9266H48.979V25.168Z"
									fill="#E73024"
								></path>
								<path fillRule="evenodd" clipRule="evenodd" d="M110.301 37.4593H118.992V40.9247H101.529V25.166H110.301V37.4593Z" fill="#E73024"></path>
								<path fillRule="evenodd" clipRule="evenodd" d="M85.935 37.4593H96.6563V40.9247H77.0015V25.166H95.6823V28.6313H85.935V31.3127H95.6823V34.778H85.935V37.4593Z" fill="#E73024"></path>
								<path fillRule="evenodd" clipRule="evenodd" d="M130.282 37.4593H138.973V40.9247H121.511V25.166H130.282V37.4593Z" fill="#E73024"></path>
								<path
									fillRule="evenodd"
									clipRule="evenodd"
									d="M130.668 14.5662H29.1613V18.2369H130.668C131.666 18.2369 132.476 17.4155 132.476 16.4022C132.476 15.3875 131.666 14.5662 130.668 14.5662ZM14.3789 10.9355H29.1613H146.994C149.988 10.9355 152.415 13.4009 152.415 16.4422C152.415 19.4849 149.988 21.9502 146.994 21.9502H29.1613V40.9262H14.3789V21.9502V10.9355Z"
									fill="#E73024"
								></path>
								<mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="167" height="52">
									<path fillRule="evenodd" clipRule="evenodd" d="M0 52H166.794V0.0839844H0V52Z" fill="white"></path>
								</mask>
								<g mask="url(#mask0)">
									<path fillRule="evenodd" clipRule="evenodd" d="M34.8467 40.9247H43.2932V25.166H34.8467V40.9247Z" fill="#E73024"></path>
									<path fillRule="evenodd" clipRule="evenodd" d="M141.408 40.9247H149.856V25.166H141.408V40.9247Z" fill="#E73024"></path>
								</g>
							</svg>
							{/* <StaticImage src="../../images/LogoPirelli.png" alt="Pirelli" title="Pirelli" objectFit="contain" /> */}
						</div>
					</div>

					{/*<ButtonRicaricaElettricaEv countryCode={countryCode} ricaricaElettrica={ricaricaElettrica} />*/}

					{!hideComponent && <ButtonChangeLanguage reference={btnRef} countryCode={countryCode} toggle={toggle} changeToggle={setToggle} />}

					<ButtonSupport reference={btnRefSupport} handleScroll={handleScroll} toggle={toggleSupport} changeToggle={setToggleSupport} supports={supports} />
				</div>
			</div>
		</div>
	);
};

export default Navbar;
