import * as React from "react";

const Question: React.FC = () => (
  <svg width="11" height="18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 4.807a4.777 4.777 0 0 1 1.353-2.386A4.787 4.787 0 0 1 5.66 1.086"
      stroke="#D52B1E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.66 12.934v-.6c0-2.949 4.784-1.91 4.784-6.462a4.78 4.78 0 0 0-2.51-4.208"
      stroke="#292B31"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.665 17.088a1.17 1.17 0 1 0 0-2.338 1.17 1.17 0 0 0 0 2.338Z"
      fill="#292B31"
    />
  </svg>
);

export default Question;
