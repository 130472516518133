import * as React from "react";
import * as style from "./style.module.scss";
import WhatsappIcon from "../Icons/WhatsappIcon";
import Question from "../Icons/Question";
import Mail from "../Icons/Mail";

const ButtonSupport = ({ handleScroll, supports, reference, toggle, changeToggle }) => {
  /*const [toggle, setToggle] = React.useState(false);*/

  return (
    <div className={style.buttonSupport}>
      <div
        className={`${style.currentChoose} ${toggle ? style.open : ""} btnDropdownSupport`}
        /*className={`${style.currentChoose} ${toggle ? style.open : ""}`}
        onClick={() => setToggle(!toggle)}
        onKeyPress={() => setToggle(!toggle)}*/
        onClick={() => changeToggle(!toggle)}
        ref={reference}
        role="button"
        tabIndex={0}
      >
        <p>{supports.title}</p>
      </div>

      <div
        className={`${style.buttonSupportList} ${toggle ? style.visible : ""}`}
      >
        <a
          onClick={() => {
            window.dataLayer.push({
              event: "GAevent",
              eventName: "call_to_action",
              cta_name: "whatsapp",
            });
            let uriWA =
              "https://wa.me/" +
              process.env.GATSBY_WHATSAPP_PHONE +
              "?text=Ciao, ho visto i vostri pacchetti Pirelli Care e vorrei saperne di più, ne parliamo?";
            window.open(uriWA, "_blank");
            changeToggle(!toggle);
          }}
        >
          <div className={style.iconWrapper}>
            <WhatsappIcon />
          </div>

          {supports.chat}
        </a>
        <a
          onClick={() => {
              changeToggle(!toggle);
            window.location.href = "mailto:support@pirellicare.com";
          }}
        >
          <div className={style.iconWrapper}>
            <Mail />
          </div>
          {supports.mail}
        </a>
        <a
          onClick={() => {
            changeToggle(!toggle);
            handleScroll("faq");
          }}
        >
          <div className={style.iconWrapper}>
            <Question />
          </div>
          {supports.faq}
        </a>
      </div>
    </div>
  );
};

export default ButtonSupport;
