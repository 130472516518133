import React from "react";
import ButtonLink from "../_partials/ButtonLink";
import ArrowRight from "../Icons/ArrowRight";
import * as styles from "./style.module.scss";
import WhatsappIcon from "../Icons/WhatsappIcon";
import PirelliCareAppDownload from "../Icons/PirelliCareAppDownload";

const Button = (props) => {
	const { tabletDown, notA, width, height, buttonTextColor, buttonBackgroundColor, mainText, smallText, waLink, onClick, style, href, testId, downloadAppIcon, border, mblCta,padding } = props;
	return (
		<div className={`${props.className} ${styles.btn}`}>
			<ButtonLink
				href={href}
				onClick={onClick}
				style={{
					width: !waLink ? width : tabletDown ? "60px" : "auto",
					height: height,
					backgroundColor: buttonBackgroundColor,
					color: buttonTextColor,
					border: border,
					padding: padding,
					...(style || {}),
				}}
				notA={notA}
				waLink={waLink}
				testId={testId}
			>
				<div className={downloadAppIcon ? styles.mblOnlyIcon : styles.mblwithIcon}>
					<div className={styles.mainText}>{mainText}</div>
					{smallText && <div className={styles.smallText}>{smallText}</div>}
				</div>

				{waLink ? <WhatsappIcon /> :
					mblCta ? <ArrowRight tabletDown={tabletDown} white={tabletDown} /> :
					tabletDown ? <PirelliCareAppDownload /> : <ArrowRight tabletDown={tabletDown} white={tabletDown} />
				}
			</ButtonLink>
		</div>
	);
};

export default Button;
