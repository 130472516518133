import React from "react";

export default function ArrowRight(props) {
  const { white, tabletDown } = props;
  return (
    <svg focusable="false" data-key="forward" aria-hidden="true" viewBox="0 0 52 52" style={{ fill: `${white ? "white" : "#D52B1E"}`, width: `${tabletDown ? "15px" : "14px"}` }}>
      <g>
        <path d="M3.4 29h33.2c.9 0 1.3 1.1.7 1.7l-9.6 9.6c-.6.6-.6 1.5 0 2.1l2.2 2.2c.6.6 1.5.6 2.1 0L49.5 27c.6-.6.6-1.5 0-2.1L32 7.4c-.6-.6-1.5-.6-2.1 0l-2.1 2.1c-.6.6-.6 1.5 0 2.1l9.6 9.6c.6.7.2 1.8-.7 1.8H3.5c-.8 0-1.5.6-1.5 1.4v3c0 .8.6 1.6 1.4 1.6z"></path>
      </g>
    </svg>
  );
}
