import React from "react";
import * as style from "./style.module.scss";

const Logo = (props) => {
  return (
    <div className={style.logo_container}>
      <svg xmlns="http://www.w3.org/2000/svg" className={style.logo} width={props.width || "300"} height={props.height || "35"} viewBox="0 0 300 35" fill="none">
        <g clipPath="url(#clip0_12965:2903)">
          <path
            d="M268.734 26.1891C270.819 29.1848 274.08 30.7904 277.607 30.7904C281.929 30.7904 284.982 28.9695 287.541 26.1891L290.461 28.8911C287.295 32.5526 283.446 35.0001 277.474 35.0001C272.507 35.0001 268.051 32.7288 265.169 28.8911L268.734 26.1891Z"
            fill="#DE211B"
          />
          <path
            d="M191.132 26.1891C193.217 29.1848 196.478 30.7904 200.005 30.7904C204.327 30.7904 207.38 28.9695 209.939 26.1891L212.859 28.8911C209.693 32.5526 205.844 35.0001 199.872 35.0001C194.904 35.0001 190.449 32.7288 187.567 28.8911L191.132 26.1891Z"
            fill="#DE211B"
          />
          <path
            d="M219.022 8.39039L217.638 4.49395C221.05 2.88838 224.406 1.83105 228.9 1.83105C237.356 1.83105 242.076 6.29532 242.076 14.6952V34.2557H237.64V29.4194C235.479 32.3369 231.895 34.9215 226.435 34.9215C220.69 34.9215 214.869 31.5733 214.869 24.8964C214.869 18.0826 220.254 14.4994 228.122 14.4994C232.085 14.4994 234.891 15.0476 237.659 15.87V14.7539C237.659 8.99738 234.246 6.0212 228.426 6.0212C224.766 6.04078 221.903 7.03937 219.022 8.39039ZM219.552 24.7398C219.552 28.832 223.155 31.1816 227.345 31.1816C233.033 31.1816 237.716 27.5985 237.716 22.5077V19.414C235.441 18.7287 232.388 18.0434 228.615 18.0434C222.795 18.0434 219.552 20.6475 219.552 24.7398Z"
            fill="#DE211B"
          />
          <path
            d="M250.741 34.256H246.134V2.2621H250.741V10.6228C253.016 5.29702 257.51 1.45932 263.387 1.71386V6.84385H263.027C256.258 6.84385 250.741 11.8564 250.741 21.5094V34.256Z"
            fill="#DE211B"
          />
          <path
            d="M262.989 19.747C262.932 19.1791 262.913 18.5917 262.913 17.9848C262.913 8.54714 269.302 1.00879 278.005 1.00879C287.314 1.00879 292.679 8.6842 292.679 18.2393C292.679 18.6701 292.679 19.1791 292.623 19.747H267.653H262.989ZM287.94 16.1442C287.447 10.231 284.167 5.08145 277.891 5.08145C272.412 5.08145 268.278 9.80026 267.653 16.1442H287.94Z"
            fill="#DE211B"
          />
          <path
            d="M209.125 9.54579C206.755 7.13744 204.043 5.27733 200.1 5.27733C193.616 5.27733 188.667 10.9556 188.667 18.1023C188.667 18.7289 188.705 19.3554 188.781 19.9624H183.87C183.813 19.3946 183.794 18.8268 183.794 18.2394C183.794 8.86049 190.847 0.930542 200.176 0.930542C206.167 0.930542 209.921 3.49554 212.879 6.70668L209.125 9.54579Z"
            fill="#DE211B"
          />
          <path
            d="M4.47446 34.9999H0V0.930542H12.4565C20.0213 0.930542 24.9129 5.25775 24.9129 11.9346C24.9129 19.7666 18.3529 23.2715 11.8687 23.2715H4.47446V34.9999ZM4.47446 19.003H12.0014C17.1016 19.003 20.4005 16.2814 20.4005 12.0912C20.4005 7.74442 17.4049 5.25775 12.191 5.25775H4.47446V19.003Z"
            fill="#DE211B"
          />
          <path d="M35.473 34.9999H30.9985V0.930542H35.473V34.9999Z" fill="#DE211B" />
          <path
            d="M71.9143 34.9999H66.416L57.0879 22.0966H48.5371V34.9999H44.0626V0.930542H58.3013C65.6197 0.930542 70.3406 4.98363 70.3406 11.2884C70.3406 16.4576 67.2881 20.0603 61.9226 21.2939L71.9143 34.9999ZM48.5181 17.8673H57.979C62.7378 17.8673 65.8282 15.3415 65.8282 11.4255C65.8282 7.50946 62.9843 5.25775 58.0169 5.25775H48.5181V17.8673Z"
            fill="#DE211B"
          />
          <path d="M101.586 34.9999H77.2607V0.930542H101.339V5.21859H81.7352V15.6939H99.2918V19.982H81.7352V30.7315H101.586V34.9999Z" fill="#DE211B" />
          <path d="M130.613 34.9999H108.051V0.930542H112.526V30.6727H130.632V34.9999H130.613Z" fill="#DE211B" />
          <path d="M159.052 34.9999H136.49V0.930542H140.964V30.6727H159.071V34.9999H159.052Z" fill="#DE211B" />
          <path d="M169.708 34.9999H165.233V0.930542H169.708V34.9999Z" fill="#DE211B" />
          <path
            d="M295.258 5.35618H294.557V2.83035H293.647V2.1842H296.168V2.83035H295.258V5.35618ZM298.386 4.59256H298.33L297.476 3.20237V5.3366H296.813V2.1842H297.552L298.349 3.51565L299.164 2.1842H299.884V5.35618H299.221V3.20237L298.386 4.59256Z"
            fill="#DE211B"
          />
        </g>
        <defs>
          <clipPath id="clip0_12965:2903">
            <rect width="300" height="34.0694" fill="white" transform="translate(0 0.930542)" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};

export default Logo;
