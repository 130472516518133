import * as React from "react";
import * as style from "./style.module.scss";
import WhatsappIcon from "../Icons/WhatsappIcon";

function MblwhatsappBtn() {
    return (
        <div className={style.whatsappBtn}>
            <a href={"https://wa.me/" + process.env.GATSBY_WHATSAPP_PHONE + "?text=Ciao, ho visto i vostri pacchetti Pirelli Care e vorrei saperne di più, ne parliamo?"}
               onClick={(e) => {
                   window.dataLayer.push({
                       event: "GAevent",
                       eventName: "call_to_action",
                       cta_name: "whatsapp",
                   });
               }}>

                <svg width="45" height="46" viewBox="0 0 45 46" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                    <rect x="0.294922" y="0.265625" width="44.7049" height="44.8882" fill="url(#pattern0)"/>
                    <path d="M2.47656 42.8413L5.33683 32.3745C3.57829 29.311 2.64606 25.8434 2.64606 22.2906C2.64606 11.1643 11.6718 2.10156 22.7527 2.10156C28.1342 2.10156 33.1768 4.20769 36.9905 8.01574C40.783 11.8238 42.8805 16.9083 42.8805 22.2906C42.8805 33.4169 33.8548 42.4796 22.7739 42.4796C19.4051 42.4796 16.0999 41.6287 13.1549 40.0118L2.47656 42.8413Z" fill="url(#paint0_linear_7906_97650)"/>
                    <path d="M1.75391 43.5514L4.72011 32.7229C2.89801 29.5531 1.94459 25.9578 1.94459 22.2774C1.9234 10.7256 11.2669 1.34375 22.7504 1.34375C28.3226 1.34375 33.5558 3.5137 37.4967 7.47067C41.4375 11.4276 43.5986 16.6823 43.5986 22.2774C43.5986 33.8079 34.255 43.1897 22.7716 43.1897C19.2757 43.1897 15.8646 42.3175 12.8136 40.6369L1.75391 43.5514ZM13.3009 36.8714L13.9365 37.2543C16.5849 38.8286 19.6359 39.6795 22.7504 39.6795C32.2846 39.6795 40.0603 31.872 40.0603 22.2987C40.0603 17.6609 38.2594 13.2785 34.9966 10.0023C31.7338 6.72608 27.3692 4.89651 22.7504 4.89651C13.195 4.89651 5.41929 12.7041 5.41929 22.2774C5.41929 25.5536 6.33033 28.766 8.06768 31.5316L8.47024 32.1911L6.7117 38.6158L13.3009 36.8714Z" fill="url(#paint1_linear_7906_97650)"/>
                    <rect x="10.2539" y="10.8906" width="25.0009" height="23.6141" fill="url(#pattern1)"/>
                    <path fillRule="evenodd" clipRule="evenodd" d="M17.5629 13.5007C17.1815 12.6285 16.7578 12.6072 16.3976 12.6072C16.0798 12.5859 15.7408 12.5859 15.3806 12.5859C15.0416 12.5859 14.4696 12.7136 13.9823 13.2454C13.4949 13.7773 12.1602 15.0325 12.1602 17.6066C12.1602 20.1808 14.0246 22.6698 14.2789 23.0102C14.5331 23.3506 17.8807 28.7967 23.1775 30.9029C27.5844 32.6473 28.4743 32.307 29.4277 32.2006C30.3811 32.0942 32.4999 30.9454 32.9448 29.7115C33.3685 28.4989 33.3685 27.4352 33.2414 27.2225C33.1143 27.0097 32.7541 26.8821 32.2456 26.6055C31.7159 26.3502 29.1735 25.0738 28.6862 24.9036C28.1989 24.7334 27.8599 24.6483 27.5209 25.1589C27.1819 25.6907 26.1861 26.8608 25.8683 27.2012C25.5716 27.5416 25.2538 27.5841 24.7453 27.3288C24.2157 27.0736 22.5419 26.5204 20.5503 24.7334C19.0036 23.3506 17.9654 21.6274 17.6476 21.1168C17.351 20.585 17.6053 20.3084 17.8807 20.0531C18.1138 19.8191 18.4104 19.4362 18.6646 19.1383C18.9189 18.8405 19.0036 18.6065 19.1943 18.2661C19.3638 17.9257 19.279 17.6066 19.1519 17.3513C19.0248 17.1173 18.029 14.5431 17.5629 13.5007Z" fill="white"/>
                    <defs>
                        <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                            <use xlinkHref="#image0_7906_97650"/>
                        </pattern>
                        <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1">
                            <use xlinkHref="#image1_7906_97650"/>
                        </pattern>
                        <linearGradient id="paint0_linear_7906_97650" x1="22.6743" y1="42.839" x2="22.6743" y2="2.09414" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#20B038"/>
                            <stop offset="1" stopColor="#60D66A"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_7906_97650" x1="22.6721" y1="43.5561" x2="22.6721" y2="1.35007" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#F9F9F9"/>
                            <stop offset="1" stopColor="white"/>
                        </linearGradient>
                        <image id="image0_7906_97650" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANMAAADTCAYAAAAbBybZAAAACXBIWXMAAAsSAAALEgHS3X78AAAA"/>
                        <image id="image1_7906_97650" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAHYAAABvCAYAAAA5fjRCAAAACXBIWXMAAAsSAAALEgHS3X78AAAA"/>
                    </defs>
                </svg>

            </a>

        </div>
    )
}

export default MblwhatsappBtn;
