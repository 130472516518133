import * as React from "react";

const IconEng: React.FC = () => (
  <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#a)">
      <path d="M-7 0v20h33.548V0H-7Z" fill="#012169" />
      <path d="m-7 0 33.548 20L-7 0Zm33.548 0L-7 20Z" fill="#000" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.862 9.996-8.035 1.711l2.071-3.43L9.775 7.664 25.513-1.72l2.071 3.43-13.897 8.285 13.897 8.285-2.07 3.43-15.74-9.382-15.738 9.382-2.071-3.43L5.862 9.996Z"
        fill="#fff"
      />
      <path d="M26.548 0-7 20Z" fill="#000" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.165 10.003-7.691 1.146l1.38-2.287L9.773 8.448l16.084-9.589 1.38 2.287-14.855 8.857 14.856 8.856-1.381 2.287-16.084-9.588-16.084 9.588-1.38-2.287 14.856-8.856Z"
        fill="#C8102E"
      />
      <path
        d="M-7.672 7.336H7.09v-8h5.368v8h14.761v5.333h-14.76v8H7.088v-8H-7.67V7.336Z"
        fill="#C8102E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.418-1.336h6.71v8h14.76v6.667h-14.76v8h-6.71v-8H-8.344V6.664H6.418v-8ZM7.759-.003v8H-7v4h14.76v8h4.026v-8h14.762v-4H11.785v-8H7.76Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="a">
        <rect width="20" height="20" rx="10" fill="#fff" />
      </clipPath>
    </defs>
  </svg>
);

export default IconEng;
