import React, { useEffect, useRef } from "react";
import Button from "../Button";
import * as style from "./style.module.scss";
import { StaticImage } from "gatsby-plugin-image";

const StickyElement = (props) => {
  const { isDesktop, tabletDown, countryCode, concorsoMonza } = props;
  const imagePath = "../../images/";
  const onboardingLinks =
    countryCode === "it"
      ? `${process.env.GATSBY_ONBOARDING_PORTAL_LINK}/it/`
      : `${process.env.GATSBY_ONBOARDING_PORTAL_LINK}/en-US/`;

  //const stickyTimeline = useRef();
  const wrapperEventElements = useRef();
  //const wrapperSelector = gsap.utils.selector(wrapperEventElements);

  useEffect(() => {
    /*    const timeline = (stickyTimeline.current = gsap.timeline({ repeat: -1 }));
		
				wrapperSelector(".sticky-event").map((element) => {
					gsap.set(element, { y: 20, autoAlpha: 0 });
					timeline.to(element, { y: 0, autoAlpha: 1, duration: 0.8 });
					timeline.to(element, { y: -20, autoAlpha: 0, duration: 0.8 }, "<3");
				});*/
  }, [isDesktop]);

  return (
    <div className={style.stickyElement}>
      {isDesktop && (
        <div className={style.wrapperEventElements} ref={wrapperEventElements}>
          <div className={`${style.stickyElement_text} sticky-event`}>
            <div className={style.stickyElement_text_main}>
              <div className={style.wrapperIconFamily}>
                <StaticImage
                  src={`${imagePath}icon-car.png`}
                  alt="icon family car"
                />
              </div>

              <div>
                <p
                  className={style.title}
                  dangerouslySetInnerHTML={{
                    __html: props.stickyElementData.YourDrivingStyleTitle,
                  }}
                />
                <p
                  className={style.description}
                  dangerouslySetInnerHTML={{
                    __html: props.stickyElementData.YourDrivingStyleDescription,
                  }}
                />
              </div>
            </div>
          </div>

          {/*       <div className={`${style.stickyElement_text} sticky-event`}>
            <div className={style.stickyElement_text_main}>
              <div className={style.wrapperIconFamily}>
                <StaticImage
                  src={`${imagePath}car-family.png`}
                  alt="icon family car"
                />
              </div>

              <div>
                <p
                  className={style.title}
                  dangerouslySetInnerHTML={{
                    __html: props.stickyElementData.ourPlansEventTitle,
                  }}
                />
                <p
                  className={style.description}
                  dangerouslySetInnerHTML={{
                    __html: props.stickyElementData.ourPlansEventDescription,
                  }}
                />
              </div>
            </div>
          </div>
       */}
        </div>
      )}

      <div className={style.cta_container}>
        {tabletDown ? (
          <div className={`${style.cta_packages} ${style.cta_packages_mbl}`}>
            {concorsoMonza ? (
              <Button
                tabletDown={tabletDown}
                mainText="Acquista ora"
                height="60px"
                buttonTextColor={"white"}
                buttonBackgroundColor={"#D52B1E"}
                href={`${onboardingLinks}?bundle=9379700`}
                testId="btn_configure_packet"
                mblCta
                onClick={(e) => {
                  window.dataLayer.push({
                    event: "GAevent",
                    eventName: "call_to_action",
                    cta_name: `start-funnel-${props.stickyElementData.bundlePlan}`,
                  });
                }}
              />
            ) : (
              <Button
                tabletDown={tabletDown}
                mainText={props.stickyElementData.configurePlanBtn}
                height="60px"
                buttonTextColor={"white"}
                buttonBackgroundColor={"#D52B1E"}
                href={`${onboardingLinks}?bundle=9379700`}
                testId="btn_configure_packet"
                mblCta
                onClick={(e) => {
                  window.dataLayer.push({
                    event: "GAevent",
                    eventName: "call_to_action",
                    cta_name: `start-funnel-${props.stickyElementData.bundlePlan}`,
                  });
                }}
              />
            )}
          </div>
        ) : (
          <div className={`${style.cta_wa}`}>
            <Button
              tabletDown={tabletDown}
              mainText={props.stickyElementData.chatBtn}
              height="60px"
              buttonTextColor="#292B31"
              buttonBackgroundColor="#fff"
              border="1px solid #292B31"
              waLink
              testId="btn_whatsapp"
              href={
                "https://wa.me/" +
                process.env.GATSBY_WHATSAPP_PHONE +
                "?text=Ciao, ho visto i vostri pacchetti Pirelli Care e vorrei saperne di più, ne parliamo?"
              }
              onClick={(e) => {
                window.dataLayer.push({
                  event: "GAevent",
                  eventName: "call_to_action",
                  cta_name: "whatsapp",
                });
              }}
            />
          </div>
        )}

        <div className={`${style.cta_packages}`}>
          {concorsoMonza ? (
            <Button
              tabletDown={tabletDown}
              mainText="Acquista ora"
              height="60px"
              buttonTextColor={tabletDown ? "black" : "white"}
              buttonBackgroundColor={tabletDown ? "white" : "#D52B1E"}
              href={
                tabletDown
                  ? "https://www.pirellicare.com/app"
                  : `${onboardingLinks}?bundle=9379700`
              }
              testId="btn_configure_packet"
              downloadAppIcon
              border={tabletDown ? "1px solid #D52B1E" : "none"}
              onClick={(e) => {
                window.dataLayer.push({
                  event: "GAevent",
                  eventName: "call_to_action",
                  cta_name: tabletDown
                    ? "download_app"
                    : `start-funnel-${props.stickyElementData.bundlePlan}`,
                });
              }}
            />
          ) : (
            <Button
              tabletDown={tabletDown}
              mainText={
                tabletDown ? null : props.stickyElementData.configurePlanBtn
              }
              height="60px"
              buttonTextColor={tabletDown ? "black" : "white"}
              buttonBackgroundColor={tabletDown ? "white" : "#D52B1E"}
              href={
                tabletDown
                  ? "https://www.pirellicare.com/app"
                  : `${onboardingLinks}?bundle=9379700`
              }
              testId="btn_configure_packet"
              downloadAppIcon
              border={tabletDown ? "1px solid #D52B1E" : "none"}
              onClick={(e) => {
                window.dataLayer.push({
                  event: "GAevent",
                  eventName: "call_to_action",
                  cta_name: tabletDown
                    ? "download_app"
                    : `start-funnel-${props.stickyElementData.bundlePlan}`,
                });
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default StickyElement;
