// extracted by mini-css-extract-plugin
export var stickyElement = "style-module--stickyElement--fEVWS";
export var stickyElement_text = "style-module--stickyElement_text--JUo8h";
export var stickyElement_text_main = "style-module--stickyElement_text_main--2QAEu";
export var wrapperIconEV = "style-module--wrapperIconEV--N80iU";
export var wrapperIconBell = "style-module--wrapperIconBell--pg9gt";
export var wrapperIconFamily = "style-module--wrapperIconFamily--5svOy";
export var wrapperIconTicket = "style-module--wrapperIconTicket--3X74u";
export var wrapperTextIcon = "style-module--wrapperTextIcon--2L-F-";
export var title = "style-module--title--1DMn0";
export var description = "style-module--description--yL3YB";
export var stickyElement_text_small = "style-module--stickyElement_text_small--3-jlQ";
export var cta_packages = "style-module--cta_packages--2mixE";
export var cta_packages_mbl = "style-module--cta_packages_mbl--31Rrf";
export var cta_wa = "style-module--cta_wa--1rQsn";
export var cta_container = "style-module--cta_container--3aEXu";
export var wrapperEventElements = "style-module--wrapperEventElements--BDucn";
export var stickyEventSalone = "style-module--stickyEventSalone--pLo2T";
export var shadow = "style-module--shadow--do_rb";