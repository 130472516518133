import * as React from "react";

const Mail: React.FC = () => (
  <svg width="18" height="14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17 11.062c0 .82-.526 1.477-1.182 1.477H2.182C1.526 12.54 1 11.882 1 11.062V2.563c0-.82.526-1.477 1.182-1.477h13.636c.656 0 1.182.658 1.182 1.477v8.5Z"
      stroke="#292B31"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m3.168 3.398 1.192.923C5.563 5.364 7.196 6 8.995 6c1.8 0 3.433-.64 4.636-1.68l1.192-.922"
      stroke="#D52B1E"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Mail;
