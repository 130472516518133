import * as styles from "./style.module.scss";
import React from "react";

const ButtonLink = (props) => {
	const { modalBtn, children, onClick, style, href, notA, waLink, testId } = props;
	const classes = `${styles.aContainer} ${modalBtn ? styles.modalBtn : ""} ${waLink ? styles.onlyIcon : ""} ${props.className || ""}`;
	const data = testId ? { "data-test-id": testId } : {};
	if (notA) {
		return (
			<div className={classes} onClick={onClick} style={style} aria-hidden="true" {...data}>
				<div className={`${styles.wrapper}`}>{children}</div>
			</div>
		);
	}
	return (
		<a className={classes} href={href} onClick={onClick} style={style} {...data}>
			<div className={`${styles.wrapper}`}>{children}</div>
		</a>
	);
};
export default ButtonLink;
